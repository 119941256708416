import React, { Component } from 'react';
import axios from 'axios';
import {localAnalyticsLogEvent} from 'localAnalytics';

import './Button.scss';

class Button extends Component {

  constructor() {
    super();
    this.state = {
      data: [],
      full_width: false,
      brand: false,
      downloadInterval: '',
      downloadProcess: false,
    }
    this.link = React.createRef();
  }

  componentDidMount() {
    this.setState({
      data: this.props.data,
      full_width: this.props.fullWidth,
      brand: this.props.brand,
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      brand: newProps.brand,
      data: newProps.data,
    });
  }

  triggerLinkClick = (e, item) => {
    if(item.source != 'dash') {
      if(this.link.current){
        if (!this.state.downloadProcess && e.target !== this.link.current) {
          this.link.current.click();
        }
      }
    }
    if( item.source == 'dash') {
      e.preventDefault();
      this.trackDownloadEvent(item.token);
      this.createDashDownload(item);
    }
  }

  formatUrl = (url) => {
    if (!isNaN(url) || url.charAt(0) === '+') {
      return 'tel:'+url;
    }

    if (url.indexOf('mailto:') === -1 && url.indexOf('http://') === -1 && url.indexOf('https://') === -1 && url.indexOf('@') !== -1) {
      return 'mailto:'+url;
    }

    if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1 && url.indexOf('mailto:') === -1 && url.indexOf('anchor:') === -1 && url.indexOf('page:') === -1) {
      return 'https://'+url;
    }

    return url;
  }

  // Prepare Dash asset download
  createDashDownload = (item) => {
    if (this.state.downloadProcess) return false;

    this.setState({ downloadProcess: item.id });

    let self = this;
    let asset_id = item.download_url.split('file=');

    asset_id = asset_id[1] ? asset_id[1] : asset_id[0];

    axios
      .get(window.API + this.state.brand.link + '?action=create-dash-asset&id=item&asset_id=' + asset_id, {
        headers: {
          Pragma: 'no-cache',
          Authorization: localStorage.getItem('authToken') || ''
        }
      }).then(function (response) {
        let intervalId = setInterval(function(){
          self.getDashDownload(response.data.id);
        }, 1000);

        self.setState({ downloadInterval: intervalId })
      });
  };

  getDashDownload = async (id) => {
    try {
      const response = await axios.get(window.API + this.state.brand.link + '?action=get-dash-asset&id=' + id, {
        headers: {
          Pragma: 'no-cache',
          Authorization: localStorage.getItem('authToken') || ''
        }
      });

      if (response.data.type === 'COMPLETED') {
        clearInterval(this.state.downloadInterval);
        this.setState({ downloadProcess: false });
        window.location.href = response.data.result.successes.item.result.url;
      }
    } catch (err) {
      console.error(err);
    }
  };

  createAssetbankDownload = (item) => {
    if (this.state.downloadProcess) return false;

    this.setState({ downloadProcess: item.id });

    let self = this;
    let asset_id = item.download_url;

    axios
      .get(window.API + this.state.brand.link + '?action=get-assetbank-asset&id=item&id=' + asset_id, {
        headers: {
          Pragma: 'no-cache',
          Authorization: localStorage.getItem('authToken') || ''
        }
      }).then(function (response) {
        self.setState({ downloadProcess: false });
        window.location.href = response.data.url;
      });
  };

  trackDownloadEvent = (token) => {
    const d = new Date();
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    let item = this.state.data;
    item.downloaded = monthNames[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear();

    this.setState({
      data: item
    });

    localAnalyticsLogEvent("public_item_button_downloaded", {brandId: window.loadedBrandId, label: token});
  }

  render () {
    let button = this.state.data;
    return (
      <div className="module_button">
        <div className="module_button__container">
          <div className="button-preview-wrapper" style={{'left':(this.state.full_width ? 0 : button.left)+'px', 'marginTop':(this.state.full_width ? 0 : (window.innerWidth <= 768 ? 30 : button.top))+'px', 'position': 'relative', 'float': 'left'}}>
            <div onClick={(e) => { this.triggerLinkClick(e, button) }} className={"btn-wrap " + (button.button_hover === 'yes' ? 'is-hoverable' : '')} >
              <div className="resizable-button" style={{'width':(button.button_width)+'px', 'height':(button.button_height)+'px'}}>
                <div className="button-inner-wrapper" style={{backgroundColor:button.button_color,'--button_hover_color':button.button_hover_color, '--text_hover_color':button.text_hover_color, '--border_hover_color':button.border_hover_color, borderRadius:button.border_radius, border:button.border_width+'px solid '+button.border_color, color:button.text_color}}>
                  {button.download_url && button.download_url !== '' && button.download_url !== null && button.source !== 'dash' && button.source !== 'dropbox' && button.source !== 'google' &&
                  <a ref={this.link} href={button.download_url} style={{paddingLeft:button.padding, paddingRight:button.padding}} onClick={() => { this.trackDownloadEvent(button.token) }} download className="btn" dangerouslySetInnerHTML={{__html: button.title}}></a>
                  }

                  {this.state.downloadProcess === button.id && (
                    <div className="spinner-icon spinner-icon--20"></div>
                  )}

                  {button.download_url && button.source === 'dash' &&
                  <a style={{paddingLeft:button.padding, paddingRight:button.padding}} className="btn" dangerouslySetInnerHTML={{__html: button.title}}></a>
                  }

                  {button.download_url && button.source === 'dropbox' &&
                  <a ref={this.link} href={button.download_url} style={{paddingLeft:button.padding, paddingRight:button.padding}} onClick={(e) => { this.trackDownloadEvent(button.token); }} target={'_blank'} rel="noopener noreferrer" className="btn" dangerouslySetInnerHTML={{__html: button.title}}></a>
                  }

                  {button.download_url && button.source === 'google' &&
                  <a ref={this.link} href={button.download_url} style={{paddingLeft:button.padding, paddingRight:button.padding}} onClick={(e) => { this.trackDownloadEvent(button.token); }} target={'_blank'} rel="noopener noreferrer" className="btn" dangerouslySetInnerHTML={{__html: button.title}}></a>
                  }

                  {!button.download_url && button.url &&
                  <a ref={this.link} href={this.formatUrl(button.url)} style={{paddingLeft:button.padding, paddingRight:button.padding}} target={button.target} rel="noopener noreferrer" className="btn" dangerouslySetInnerHTML={{__html: button.title}}></a>
                  }

                  {!button.download_url && !button.url &&
                  <div className="btn" style={{paddingLeft:button.padding, paddingRight:button.padding}} dangerouslySetInnerHTML={{__html: button.title}}></div>
                  }

                  {button.download_url && button.upload_filename && (
                  <div className="file-info" style={{ top: '-'+(button.border_width*1+1)+'px'}}>
                    <div className="file-info__title">{button.preview_title}</div>
                    <div className="file-info__details">
                      <p>
                        Kind: {button.type}<br/>Size: {button.size !== 0 ? button.size : 'Unknown'}
                        {button.size_x && button.size_y && (
                        <span><br/>Dimensions: {button.size_x} x {button.size_y}</span>
                        )}
                      </p>
                      <p>
                        Uploaded: {button.uploaded}<br/>Last downloaded: {button.downloaded}
                      </p>
                      {button.preview && (
                      <p className="preview">
                        <img src={button.preview} alt=""/>
                      </p>
                      )}
                    </div>
                  </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Button;

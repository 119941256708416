import React, { Component } from 'react';
import './Spacer.scss';

import { calculatePadding } from '../../../utils';

const windowWidth = window.innerWidth;

class Spacer extends Component {

  constructor() {
    super();
    this.state = {
      data: []
    }
  }

  componentDidMount() {
    this.setState({
      data: this.props.data
    });
  }

  render () {
    return (
      <div
        className={'module module--spacing'}
        style={{
          paddingTop: calculatePadding(windowWidth, this.state.data.row_padding_top)+'px',
          paddingBottom: calculatePadding(windowWidth, this.state.data.row_padding_bottom)+'px',
          backgroundColor:this.state.data.background
        }}
      >
        <div className="module-spacing row">
          <div>
            <div className="module-spacing__line" style={{borderColor:this.state.data.line}}></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Spacer;

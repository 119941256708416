import React, { Component } from 'react';

import './MobAlert.scss';
import corebookWhiteLogo  from 'assets/img/corebook-logo-white.png';
import laptopImg          from 'assets/img/photo-1525548002014-e18135d814d7.png';
import mobileSvgButton    from 'assets/img/mobileSvgButton.svg'

class MobAlert extends Component {

  constructor() {
    super();
    this.state = {
      data: [],
    }
  }

  componentDidMount() {
    this.setState({
      data: this.props.data,
    });
  }

  render () {
    return (
      <div className="mobile-alert-popup">
        <div className="mobile-alert">
          <div className="mobile-alert__logo">
            <a href={'https://www.'+window.MAIN_DOMAIN}>
              <img src={corebookWhiteLogo} alt="Corebook logo" />
            </a>
          </div>
          <div className="mobile-alert__content">
            <img src={laptopImg} alt="Laptop" />
            <div className="mobile-alert__text-box">
              <h6>{this.props.brand} <br />brand are desktop only.</h6>
              <p>Please visit from your desktop device.</p>
            </div>
          </div>
          <div className="mobile-alert__input">
            <img src={mobileSvgButton} alt="" />

            <div>{this.props.link}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default MobAlert;

import React, { Component } from 'react';

class VideoText extends Component {

  constructor() {
    super();
    this.state = {
      data: [],
      brand: false,
      full_width: false,
      isVideoLoaded: false,
      x: 0,
      y: 0,
      attachmentClass: 'btn-download-float',
      videoMuted: true
    }
    this.hoverVideo = React.createRef();
    this.mainVideo = React.createRef();
  }

  componentDidMount() {

    const urlParams = new URLSearchParams(window.location.search);

    if (this.isMobileView()) {
      this.props.data.text_pos = this.props.imageVideoTextPosition;
    }

    this.setState({
      data: this.props.data,
      brand: this.props.brand,
      full_width: this.props.fullWidth
    });

    if(
        urlParams.get('p') &&
        urlParams.get('m')*1 === this.props.data.content_module_id &&
        urlParams.get('p')*1 === this.props.data.id
    ) {
      let self = this;

      setTimeout(function(){
        self.showModal(self.state.data);
      },1000);
    }

    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  isMobileView() {
    return window.innerWidth <= 768;
  }

  handleScroll = (event) => {
    this.setState({ attachmentClass: 'btn-download-float' });
  }

  onLoadedData = () => {
    this.setState({
      isVideoLoaded: true
    });
  };

  imageHasDownloads = (item) => {
    return item.attachments && item.attachments.length > 0;
  }

  toggleMute = (item) => {
    if (this.mainVideo.current.muted === false) {
      this.setState({videoMuted: true});
      this.mainVideo.current.muted = true;
    }
    else {
      this.setState({videoMuted: false});
      this.mainVideo.current.muted = false;
    };
  }

  showModal = (item) => {
    if(!this.imageHasDownloads(item)) return false;
    this.setState({ attachmentClass: 'btn-download-float' });
    this.props.openModal(item);
  }

  formatUrl = () => {
    let url = this.state.data.url;

    if (!url) return false;

    if (!isNaN(url) || url.charAt(0) === '+') {
      return 'tel:'+url;
    }

    if (url.indexOf('mailto:') === -1 && url.indexOf('http://') === -1 && url.indexOf('https://') === -1 && url.indexOf('@') !== -1) {
      return 'mailto:'+url;
    }

    if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1 && url.indexOf('mailto:') === -1 && url.indexOf('anchor:') === -1 && url.indexOf('page:') === -1) {
      return 'https://'+url;
    }

    return url;
  }

  rewriteUrls = (text) => {
    if (text) {
      if (!this.state.brand.has_master) {
        return text;
      };

      const linkToReplace = 'https://my.' + window.MAIN_DOMAIN + '/' + this.state.brand.master_links.link;
      const linkToReplaceWith = this.state.brand.domain ? 'https://' + this.state.brand.domain : 'https://my.' + window.MAIN_DOMAIN + '/ ' + this.state.brand.link;

      var urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, function(url) {
        return url.replace(linkToReplace, linkToReplaceWith);
      })
    }
  }

  _onMouseEnter(e) {
    this.setState({ attachmentClass: 'btn-download-float visible' });
    if (this.hoverVideo.current) {
      this.hoverVideo.current.currentTime = 0;
    }
  }

  _onMouseMove(e) {
    this.setState({
      x: e.clientX + 5,
      y: e.clientY - 30,
      attachmentClass: 'btn-download-float visible'
    });
  }

  _onMouseLeave(e) {
    this.setState({ attachmentClass: 'btn-download-float' });
  }

  render () {
    return (
      <div className="module_video">
        <div className={this.state.data.video_sound === 'yes' ? 'video with--sound' : 'video'}>
        <div className="dynamic-content" style={{}}>
          {this.state.data.source && this.state.data.text && this.state.data.text_pos === 'top' && (
          <div className="text" style={{width: this.state.data.text_width, padding: '0 0 10px', maxWidth: '100%'}}>
            <div className="editor-content" dangerouslySetInnerHTML={{__html: this.rewriteUrls(this.state.data.text)}}></div>
          </div>
          )}

          {this.state.data.source && (
          <div
            className={this.state.data.hover ? 'image with--hover' : 'image'}
            onMouseEnter={this._onMouseEnter.bind(this)}
            onMouseMove={this._onMouseMove.bind(this)}
            onMouseLeave={this._onMouseLeave.bind(this)}
            style={{'width':(this.state.full_width ? '100%' : this.state.data.width+'px'), 'left':(this.state.full_width ? 0 : this.state.data.left)+'px', 'marginTop':(this.state.full_width ? 0 : this.state.data.top)+'px', maxWidth: '100%', position: 'relative', cursor: this.imageHasDownloads(this.state.data) ? 'pointer' : 'initial', borderRadius:this.state.data.border_radius }}
          >
            <div style={{right: this.state.data.border_radius > 10 ? this.state.data.border_radius/3+6+'px' : '', 'bottom': this.state.data.border_radius > 10 ? this.state.data.border_radius/3+6+'px' : ''}} className={this.state.videoMuted ? 'sound-block unmuted' : 'sound-block'}>
                <div className="mute sound-toggle-box" onClick={(e) => { e.preventDefault(); this.toggleMute(this.state.data.id) }}>
                    <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g >
                    <path d="M15.5 0C14.96 0 14.43 0.18 14 0.5L6.67 6H3C1.34 6 0 7.34 0 9V15C0 16.66 1.34 18 3 18H6.67L14 23.5C14.43 23.82 14.96 24 15.5 24C16.88 24 18 22.88 18 21.5V2.5C18 1.12 16.88 0 15.5 0ZM16 21.5C16 21.59 15.97 21.67 15.92 21.74C15.76 21.98 15.44 22.05 15.2 21.9L7.6 16.2C7.43 16.07 7.22 16 7 16H3C2.45 16 2 15.55 2 15V9C2 8.45 2.45 8 3 8H7C7.22 8 7.43 7.93 7.6 7.8L15.2 2.1C15.27 2.05 15.36 2.02 15.44 2.02C15.73 2 15.98 2.21 16 2.5V21.5Z" fill="black"/>
                    </g>
                    </svg>

                </div>
                <div className="unmute sound-toggle-box" onClick={(e) => { e.preventDefault(); this.toggleMute(this.state.data.id) }}>
                    <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g>
                    <path d="M12.41 6.69999C12.08 6.25999 12.17 5.62999 12.61 5.29999L19 0.499992C20.1 -0.330008 21.67 -0.100008 22.5 0.999992C22.82 1.42999 23 1.95999 23 2.49999V13.5C23 14.05 22.55 14.5 22 14.5C21.45 14.5 21 14.05 21 13.5V2.49999C20.99 2.21999 20.75 1.99999 20.47 2.00999C20.37 2.00999 20.27 2.04999 20.19 2.10999L13.81 6.89999C13.37 7.22999 12.74 7.13999 12.41 6.69999ZM27.76 23.7C27.56 23.9 27.28 24.01 27 24C26.76 24 26.53 23.91 26.35 23.76L23 20.93V21.5C23 22.88 21.88 24 20.5 24C19.96 24 19.43 23.82 19 23.5L11.67 18H7.99999C6.33999 18 4.99999 16.66 4.99999 15V8.99999C4.99999 8.08999 5.41999 7.22999 6.13999 6.65999L0.349992 1.75999C-0.0700083 1.39999 -0.120008 0.769992 0.239992 0.349992C0.599992 -0.0700083 1.22999 -0.120008 1.64999 0.239992L27.65 22.24C28.07 22.6 28.12 23.23 27.76 23.65V23.7ZM21 19.23L7.77999 7.99999C7.30999 8.09999 6.98999 8.51999 6.99999 8.99999V15C6.99999 15.55 7.44999 16 7.99999 16H12C12.22 16 12.43 16.07 12.6 16.2L20.2 21.9C20.44 22.06 20.76 21.99 20.92 21.74C20.97 21.67 21 21.58 21 21.5V19.23Z" fill="black"/>
                    </g>
                    </svg>

                </div>
            </div>
            {!this.state.data.url && (
              <div style={{cursor: this.imageHasDownloads(this.state.data) ? 'pointer' : 'default'}} onClick={(e) => { e.preventDefault(); this.showModal(this.state.data) }}>
                <div className="hover-default">
                  <video ref={this.mainVideo} autoPlay loop muted playsInline onLoadedData={()=>{this.onLoadedData()}} style={{ minHeight: this.state.isVideoLoaded ? 0 : 200, borderRadius:this.state.data.border_radius, border:this.state.data.border_width+'px solid '+this.state.data.border_color }}>
                    <source src={this.state.data.source} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>

                {this.state.data.hover &&
                  <div className="hover-hover">
                    {this.state.data.hover_type === 'image' &&
                      <img src={this.state.layout !== 'one' ? this.state.data.hover_800 : (this.state.full_width ? this.state.data.hover : this.state.data.hover_1600)} style={{ borderRadius:this.state.data.border_radius, border:this.state.data.border_width+'px solid '+this.state.data.border_color }} alt=""/>
                    }

                    {this.state.data.hover_type === 'video' &&
                      <video ref={this.hoverVideo} autoPlay loop muted playsInline style={{ borderRadius:this.state.data.border_radius, border:this.state.data.border_width+'px solid '+this.state.data.border_color }}>
                        <source src={this.state.data.hover} type="video/mp4"/>
                        Your browser does not support the video tag.
                      </video>
                    }
                  </div>
                }
              </div>
            )}

            {this.state.data.url && (
              <div>
                <div className="hover-default">
                  <div>
                    <a href={this.formatUrl()} target={this.state.data.target} rel="noopener noreferrer">
                      <video autoPlay loop muted playsInline onLoadedData={()=>{this.onLoadedData()}} style={{ minHeight: this.state.isVideoLoaded ? 0 : 200, borderRadius:this.state.data.border_radius, border:this.state.data.border_width+'px solid '+this.state.data.border_color }}>
                        <source src={this.state.data.source} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </a>
                  </div>
                </div>

                {this.state.data.hover &&
                  <div className="hover-hover">
                    <div>
                      <a href={this.formatUrl()} target={this.state.data.target} rel="noopener noreferrer">
                        {this.state.data.hover_type === 'image' &&
                          <img src={this.state.layout !== 'one' ? this.state.data.hover_800 : (this.state.full_width ? this.state.data.hover : this.state.data.hover_1600)} style={{ borderRadius:this.state.data.border_radius, border:this.state.data.border_width+'px solid '+this.state.data.border_color }} alt=""/>
                        }

                        {this.state.data.hover_type === 'video' &&
                          <video ref={this.hoverVideo} autoPlay loop muted playsInline style={{ borderRadius:this.state.data.border_radius, border:this.state.data.border_width+'px solid '+this.state.data.border_color }}>
                            <source src={this.state.data.hover} type="video/mp4"/>
                            Your browser does not support the video tag.
                          </video>
                        }
                      </a>
                    </div>
                  </div>
                }
              </div>
            )}

            {this.imageHasDownloads(this.state.data) && (
            <div className={this.state.attachmentClass} style={{left: this.state.x, top:this.state.y }} onClick={() => { this.showModal(this.state.data) }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><defs><path id="wee3a" d="M1468 2620v4a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2v-4"/><path id="wee3b" d="M1454 2615l5 5 5-5"/><path id="wee3c" d="M1459 2620v-12"/></defs><g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3a"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3b"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3c"/></g></g></svg>
            </div>
            )}
          </div>
          )}

          {this.state.data.source && this.state.data.text && this.state.data.text_pos === 'bottom' && (
          <div className="text" style={{width: this.state.data.text_width, padding: '10px 0 0 0', maxWidth: '100%'}}>
            <div className="editor-content" dangerouslySetInnerHTML={{__html: this.rewriteUrls(this.state.data.text)}}></div>
          </div>
          )}
        </div>
        </div>
      </div>
    );
  }
}

export default VideoText;

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import VisibilitySensor from 'react-visibility-sensor';

import './Cards.scss';

class Cards extends Component {

  constructor() {
    super();
    this.state = {
      data: [],
      brand: false,
      visibleTab: 1,
      full_width: false,
      tabToAnimate: -1,
      contentStoped: true,
      animationStoped: false,
      animationStatus: false,
      filledTabs: [],
      filledTabsCount: 0,
      x: 0,
      y: 0,
      buttonsClass: 'btn-switch-cards',
      pointerStyle: 'initial',
      textIsSelected: false,
    }
  }

  componentDidMount() {

    // Detect filled tabs
    let filled = this.getFilledTabs(this.props.data.items);

    if (filled.length > 0) {

      this.setState({
        data: this.props.data,
        brand: this.props.brand,
        full_width: this.props.fullWidth,
        visibleTab: filled[0].id,
        filledTabs: filled,
        filledTabsCount: filled.length,
      });

      // Start animate
      setInterval(() => {
        if (!this.state.contentStoped) {
          let tabs = this.state.filledTabs;
          let animationStatus = 0;

          // Set first tab to animate
          if (this.state.tabToAnimate === -1) {
            this.setState({
              tabToAnimate: 0,
              animationStatus: 0,
              visibleTab: filled[0].id
            });
          }

          // Animate tab
          animationStatus = this.state.animationStatus + 1;
          tabs[this.state.tabToAnimate].animation = this.state.animationStatus;

          this.setState({
            animationStatus: animationStatus,
            filledTabs: tabs
          });

          // Animation end, set next tab
          if (this.state.animationStatus === 100) {
            tabs[this.state.tabToAnimate].animation = 0;
            let tabToAnimate = this.state.filledTabsCount - 1 === this.state.tabToAnimate ? 0 : this.state.tabToAnimate + 1;

            this.setState({
              animationStatus: 0,
              tabToAnimate: tabToAnimate,
              visibleTab: tabs[tabToAnimate].id,
              filledTabs: tabs
            });
          }
        }
      }, 75);

      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener('selectstart', this.selectStart);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      brand: newProps.brand,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('selectstart', this.selectStart);
  }

  getFilledTabs = (items) => {
    let filledTabs = [];

    for (let index = 0; index < items.length; index++) {
      let filled = items[index].text && items[index].text.replace( /(<([^>]+)>)/ig, '').length >= 3;

      if (filled) {
        items[index].animation = 0;
        filledTabs.push(items[index]);
      }
    }

    return filledTabs;
  }

  handleScroll = (event) => {
    this.setState({
      buttonsClass: 'btn-switch-cards'
    });
  }

  selectStart = (event) => {
    this.setState({
      buttonsClass: this.state.buttonsClass.replace(' visible',''),
      pointerStyle: 'initial',
      textIsSelected: true
    });
  }

  onChange = (isVisible) => {
    if(!this.state.animationStoped){
      this.setState({
        contentStoped: !isVisible
      });
    }else{
      this.setState({
        contentStoped: true
      });
    }
  }

  changeTab = (id) => {
    this.setState({
      visibleTab: id
    });
  }

  stopAnimate = (event) => {
    let tabs = this.state.filledTabs;

    for (let index = 0; index < tabs.length; index++) {
      tabs[index].animation = 0;
    }

    this.setState({
      contentStoped: true,
      animationStoped: true,
      tabToAnimate: -1,
      animationStatus: false,
      filledTabs: tabs
    });
  }

  _onMouseEnter(e) {
    this.setState({
      buttonsClass: 'btn-switch-cards visible',
      pointerStyle: 'pointer',
      textIsSelected: false
    });
  }

  _onMouseUp(e) {
    if (this.state.filledTabsCount === 1) return;
    let direction = this.state.buttonsClass.indexOf('btn-switch-cards--right') === -1 ? 'prev' : 'next';

    let currentIndex = 0
    let nextTabIndex = 0;

    for (let index = 0; index < this.state.filledTabs.length; index++) {
      if (this.state.visibleTab === this.state.filledTabs[index].id) {
        currentIndex = index;
      }
    }

    if (direction === 'next') {
      nextTabIndex = currentIndex === this.state.filledTabsCount - 1 ? 0 : currentIndex + 1;
    } else {
      nextTabIndex = currentIndex === 0 ? this.state.filledTabsCount - 1 : currentIndex - 1;
    }

    // If no text selected, swith tabs
    if (window.getSelection){
      if (window.getSelection().toString().length === 0){
        for (let index = 0; index < this.state.filledTabs.length; index++) {
          if (nextTabIndex === index) {
            this.changeTab(this.state.filledTabs[index].id);
          }
        }
      }
    }

    this.setState({
      pointerStyle: 'pointer',
      buttonsClass: this.state.buttonsClass + ' visible',
      textIsSelected: false
    });
  }

  _onMouseMove(e) {
    let bounds = ReactDOM.findDOMNode(this).getBoundingClientRect();
    let half = bounds.left + bounds.width / 2;

    let visible = this.state.textIsSelected ? '' : ' visible';
    if (e.clientX > half) {
      if (this.state.buttonsClass.indexOf('btn-switch-cards--right') === -1) {
        this.setState({
          buttonsClass: 'btn-switch-cards'+visible+' btn-switch-cards--right btn-switch-cards--animate'
        });

        var _self = this;
        setTimeout(function(){
          _self.setState({
            buttonsClass: 'btn-switch-cards'+visible+' btn-switch-cards--right'
          });
        },200);
      }
    } else {
      if (this.state.buttonsClass.indexOf('btn-switch-cards--right') !== -1) {
        this.setState({
          buttonsClass: 'btn-switch-cards'+visible+' btn-switch-cards--animate'
        });

        var _self = this;
        setTimeout(function(){
          _self.setState({
            buttonsClass: 'btn-switch-cards'+visible
          });
        },200);
      }
    }

    this.setState({
      x: e.clientX > half ? (e.clientX + 5) : (e.clientX - 70),
      y: e.clientY - 30
    });
  }

  _onMouseLeave(e) {
    this.setState({
      buttonsClass: 'btn-switch-cards',
      pointerStyle: 'initial'
    });
  }

  render () {
    return (
      <VisibilitySensor partialVisibility={true} minTopValue={200} onChange={this.onChange}>
        <div className="module_paragraph_column">
          <div className="dynamic-content" style={{'width':(this.state.full_width ? '100%' : this.state.data.width+'px'), 'left':(this.state.full_width ? 0 : this.state.data.left)+'px', 'marginTop':(this.state.full_width ? 0 : this.state.data.top)+'px'}}>
            <div className={'card-tabs layout--' + this.state.filledTabsCount}>
              <ul>
                {this.state.filledTabs.map(item =>
                <li key={item.id}
                  className={'animate-'+item.animation + (this.state.visibleTab === item.id && this.state.animationStoped ? ' active' : '') + (this.state.animationStoped ? ' no-animate' : '')}
                  onClick={() => {
                    this.changeTab(item.id);
                    this.stopAnimate()
                  }}>
                </li>
                )}
              </ul>
            </div>

            <div
              onMouseUp={() => {
                this._onMouseUp();
                this.stopAnimate()
              }}
              onMouseEnter={this._onMouseEnter.bind(this)}
              onMouseMove={this._onMouseMove.bind(this)}
              onMouseLeave={this._onMouseLeave.bind(this)}
              style={{cursor: this.state.filledTabsCount > 1 ? this.state.pointerStyle : 'initial', 'display': 'flex'}}>

              {this.state.filledTabs.map(item =>
              <div key={item.id}
                className={this.state.visibleTab === item.id ? 'card-content editor-content tab-content visible' : 'card-content editor-content tab-content'}
                dangerouslySetInnerHTML={{__html: item.text}}
                onMouseDown={this.stopAnimate}>
              </div>
              )}

              {this.state.filledTabsCount > 1 && (
                <div className={this.state.buttonsClass} data-tabs={this.state.filledTabsCount} style={{left: this.state.x, top:this.state.y }}>
                  <svg version="1.1" x="0px" y="0px" viewBox="0 0 100 125">
                    <path className="ico-prev-next-text-cards" d="M59.6,34.9c-0.6,0.6-0.6,1.5,0,2.1L71,48.5H31.1c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5H71L59.6,62.9
                    c-0.6,0.6-0.6,1.5,0,2.1s1.5,0.6,2.1,0l14-14c0,0,0,0,0.1-0.1c0,0,0.1,0,0.1-0.1l0,0c0.5-0.5,0.5-1.3,0-1.8l0,0c0,0-0.1,0-0.1-0.1
                    c0,0,0,0-0.1-0.1l-14-14C61.1,34.3,60.1,34.3,59.6,34.9z"/>
                  </svg>
                </div>
              )}
            </div>
          </div>
        </div>
      </VisibilitySensor>
    );
  }
}

export default Cards;

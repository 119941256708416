import React, { Component } from 'react';

class VideoBackground extends Component {

  render () {
    let props = this.props;

    return (
      <div>
        {props.src && (
        <div className="module-bg-video">
          <video key={props.id} autoPlay loop muted playsInline>
            <source src={props.src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        )}
      </div>
    );
  }
}

export default VideoBackground;

import React, { Component } from 'react';
import axios from "axios";
import './Update.scss';

class Request extends Component {
  constructor() {
    super();
    this.state = {
      brand      : '',
      studio     : '',
      page       : '',
      token      : '',
      password   : '',
      password_confirmation: '',
      emailValid : false,
      showError  : false,
      errorText  : 'Enter valid email!',
      isSending  : false,
    }

    this.clickRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      brand: this.props.brand,
      studio: this.props.studio,
      page: this.props.page,
      menu: this.props.menu
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.brand) {
      this.setState({
        brand: newProps.brand,
        studio: newProps.studio,
        page: newProps.page,
        menu: newProps.menu,
        showError: false
  	  });
    }
  }

  handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		});
	};

  handleSubmit = evt => {
		evt.preventDefault();

    if(this.state.isSending) {
      return false;
    }

    this.setState({
      showError: false,
      errorText: '',
      isSending: true
    });

		let self = this;

		axios.post(window.API + 'authorize/update', {
			password: this.state.password,
			password_confirmation: this.state.password_confirmation,
      token: localStorage.getItem('restoreToken')
		},{
      headers: {
        Pragma: 'no-cache',
      }
    }).then(function (response) {

      // Status OK
      if (response.data.status === 'ok') {
        self.setState({
          showError: false,
          isSending: false
    	  });

        self.clickRef.current.click();
      } else {
        self.setState({
          showError: true,
          errorText: response.data.errors.join(". "),
          isSending: false
        });
      }
		}).catch(error => {
      self.setState({
        showError: true,
        errorText: error,
        isSending: false
      });
		})
	};

  render () {
    return (
      <div>
        <div className={this.state.menu && this.state.menu.length > 0 ? 'login login--with-menu' : 'login'}>
          <div className="login__form">
            <h1 className="no--lock">Change password</h1>
            <p>Your password has expired.<br/>Please change your password.</p>

            <div>
              <form onSubmit={this.handleSubmit} noValidate>
                <div className="form-error">
                  <div className={this.state.showError ? 'visible' : ''}>{this.state.errorText}</div>
                </div>

                <div className="form-group">
                  <input
                    type="password"
                    value={this.state.password}
                    placeholder="New Password"
                    onChange={this.handleChange('password')}
                    />
                </div>

                <div className="form-group">
                  <input
                    type="password"
                    value={this.state.password_confirmation}
                    placeholder="Repeat Password"
                    onChange={this.handleChange('password_confirmation')}
                    />
                </div>

                <div className="form-group two-buttons">
                  <input type="submit" value={this.state.isSending ? 'Wait...' : 'Submit'} onClick={this.handleSubmit} />
                  <input type="submit" value="Cancel" onClick={() => this.props.switchScreen('login',this.state.brand,this.state.studio,this.state.page)} className="cancel" />
                </div>

                <div ref={this.clickRef} onClick={() => this.props.switchScreen('login', this.state.brand, this.state.studio, this.state.page, 'Password changed! Please sign in.')}></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Request;

import React, { Component } from 'react';
import './Login.scss';

import LoginScreen from './login/Login';
import LoginScreenElon from './elon/Login';
import RequestScreen from './request/Request';
import UpdateScreen from './update/Update';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      screen : 'login',
    }

    this.switchScreen = this.switchScreen.bind(this);
  }

  componentDidMount() {
    this.setState({
      brand: this.props.brand,
	    page: this.props.page,
      studio: this.props.studio,
      extra: false
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.brand) {
      this.setState({
        brand: newProps.brand,
        page: this.props.page,
        studio: this.props.studio,
        extra: false
  	  });

      if (newProps.brand.link === 'hTr0wIFz4XfJmUxABCfbdUujFvss92vt') {
        document.querySelectorAll(".brand-content").forEach(node => {
          node.style.setProperty('background-color', '#6c0009', 'important');
        });
      }
    }
  }

  switchScreen = (screen, brand, studio, page, extra = false) => {
		this.setState({
			'screen': screen,
      brand: brand,
      studio: studio,
      page: page,
      extra: extra
		});

    if (screen === 'request' && brand.link === 'hTr0wIFz4XfJmUxABCfbdUujFvss92vt') {
      document.querySelectorAll(".brand-content").forEach(node => {
        node.style.setProperty('background-color', '#fff', 'important');
      });
    }

    if (screen === 'login' && brand.link === 'hTr0wIFz4XfJmUxABCfbdUujFvss92vt') {
      document.querySelectorAll(".brand-content").forEach(node => {
        node.style.setProperty('background-color', '#6c0009', 'important');
      });
    }
	};

  render () {
    return (
      <div>
        {this.state.screen === 'login' && this.state.brand && this.state.brand.link !== 'hTr0wIFz4XfJmUxABCfbdUujFvss92vt' &&
        <LoginScreen brand={this.state.brand} studio={this.state.studio} page={this.state.page} extra={this.state.extra} switchScreen={this.switchScreen} />
        }

        {this.state.screen === 'login' && this.state.brand && this.state.brand.link === 'hTr0wIFz4XfJmUxABCfbdUujFvss92vt' &&
        <LoginScreenElon brand={this.state.brand} studio={this.state.studio} page={this.state.page} extra={this.state.extra} switchScreen={this.switchScreen} />
        }

        {this.state.screen === 'request' && this.state.brand &&
        <RequestScreen brand={this.state.brand} studio={this.state.studio} page={this.state.page} extra={this.state.extra} switchScreen={this.switchScreen} />
        }

        {this.state.screen === 'update' && this.state.brand &&
        <UpdateScreen brand={this.state.brand} studio={this.state.studio} page={this.state.page} extra={this.state.extra} switchScreen={this.switchScreen} />
        }
      </div>
    );
  }
}

export default Login;

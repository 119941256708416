import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import './Header.scss';

class Header extends Component {

  render () {
    return (
      <div>
        {this.props.brandVisibility !== 'locked' &&
        <div className={'burger-tap' + (this.props.menuopen ? ' open': '')} role="button">
          <div className={this.props.menuopen ? 'burger open': 'burger'} onClick={this.props.toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        }
        <div className="brand-header scroll-up">
          <div className="mobile-logo">
            {!this.props.brand.orig_image && this.props.brand.image && ( <NavLink to={'/' + (!this.props.customDomain ? this.props.brand.link : '')}><img src={this.props.brand.image} alt={this.props.brand.title} /></NavLink> )}
            {this.props.brand.orig_image && ( <NavLink to={'/' + (!this.props.customDomain ? this.props.brand.link : '')}><img src={this.props.brand.orig_image} alt={this.props.brand.title} /></NavLink> )}
          </div>
        </div>
      </div>
    );
  }
}

export default Header;

export function localAnalyticsLogEvent(event, data) {
	//uses UMAMI
	var baseurl = window.location.href;
	data.event_name = event;
	if (window.umami) {
  	window.umami.trackEvent(event, data, baseurl.replace(/^https?:\/\//, ''));
	}
}

export function localAnalyticsLogPageView() {
	var baseurl = window.location.href;
	if (window.umami) {
		window.umami.trackView(baseurl.replace(/^https?:\/\//, ''));
	}
}

export default {localAnalyticsLogEvent, localAnalyticsLogPageView}
import React, { Component } from 'react';

import './Audio.scss';

import playerPlay from 'assets/img/ico-player-play.svg';
import playerPause from 'assets/img/ico-player-pause.svg';

class Audio extends Component {

  constructor() {
    super();
    this.state = {
      data: [],
      full_width: false
    }
  }

  componentDidMount() {
    this.setState({
      data: this.props.data,
      full_width: this.props.fullWidth
    });

    const urlParams = new URLSearchParams(window.location.search);

    if(
        urlParams.get('p') &&
        urlParams.get('m')*1 === this.props.data.content_module_id &&
        urlParams.get('p')*1 === this.props.data.id
    ) {
      let self = this;

      setTimeout(function(){
        self.showModal(self.state.data);
      },1000);
    }
  }

  imageHasDownloads = (item) => {
    return item.attachments && item.attachments.length > 0;
  }

  showModal = (item) => {
    if(!this.imageHasDownloads(item)) return false;
    this.props.openModal(item);
  }

  render () {
    const props = this.props;

    return (
      <div className="module_audio">
        <div className="height_100">
          <div
            className="dynamic-content audio-content"
            style={{
              'width':this.state.data.width+'px',
              'left':(this.state.full_width ? 0 : this.state.data.left)+'px',
              'marginTop':(this.state.full_width ? 0 : this.state.data.top)+'px'
            }}>
            {this.state.data.text && this.state.data.text !== '<p><br></p>' &&
            <div className="module_audio__title editor-content" dangerouslySetInnerHTML={{__html: this.state.data.text}}></div>
            }
            <div className="relative_pos">
              <div
                className="module_audio__play"
                style={{
                  'width':this.state.data.width+'px',
                  'height':this.state.data.width+'px',
                  backgroundImage:this.state.data.background ? 'url('+this.state.data.background+')' : 'none'
                }}
                >
                {props.audio.id !== this.state.data.id &&
                <div className="ico-audio-play" onClick={() => props.playAudio(this.state.data)}>
                  <div className="ko-progress-circle" data-progress={props.audioProgress}>
                      <div className="ko-circle">
                          <div className="full ko-progress-circle__slice">
                              <div className="ko-progress-circle__fill"></div>
                          </div>
                          <div className="ko-progress-circle__slice">
                              <div className="ko-progress-circle__fill"></div>
                              <div className="ko-progress-circle__fill ko-progress-circle__bar"></div>
                          </div>
                      </div>
                      <div className="ko-progress-circle__overlay"></div>
                  </div>

                  <span>
                    <img src={playerPlay} alt="" />
                  </span>
                </div>
                }

                {props.audio.id === this.state.data.id && props.audioStatus !== 'play' && props.audioStatus !== 'resume' &&
                <div className="ico-audio-play" onClick={() => props.playAudio(this.state.data)}>
                  <div className="ko-progress-circle" data-progress={props.audioProgress}>
                      <div className="ko-circle">
                          <div className="full ko-progress-circle__slice">
                              <div className="ko-progress-circle__fill"></div>
                          </div>
                          <div className="ko-progress-circle__slice">
                              <div className="ko-progress-circle__fill"></div>
                              <div className="ko-progress-circle__fill ko-progress-circle__bar"></div>
                          </div>
                      </div>
                      <div className="ko-progress-circle__overlay"></div>
                  </div>

                  <span>
                    <img src={playerPlay} alt="" />
                  </span>
                </div>
                }

                {props.audio.id === this.state.data.id && props.audioStatus === 'play' &&
                <div className="ico-audio-play play--pause" onClick={() => props.pauseAudio(this.state.data)}>
                  <div className="ko-progress-circle" data-progress={props.audioProgress}>
                      <div className="ko-circle">
                          <div className="full ko-progress-circle__slice">
                              <div className="ko-progress-circle__fill"></div>
                          </div>
                          <div className="ko-progress-circle__slice">
                              <div className="ko-progress-circle__fill"></div>
                              <div className="ko-progress-circle__fill ko-progress-circle__bar"></div>
                          </div>
                      </div>
                      <div className="ko-progress-circle__overlay"></div>
                  </div>

                  <span>
                    <img src={playerPause} alt=""/>
                  </span>
                </div>
                }

                {props.audio.id === this.state.data.id && props.audioStatus === 'resume' &&
                <div className="ico-audio-play play--pause" onClick={() => props.pauseAudio(this.state.data)}>
                  <div className="ko-progress-circle" data-progress={props.audioProgress}>
                      <div className="ko-circle">
                          <div className="full ko-progress-circle__slice">
                              <div className="ko-progress-circle__fill"></div>
                          </div>
                          <div className="ko-progress-circle__slice">
                              <div className="ko-progress-circle__fill"></div>
                              <div className="ko-progress-circle__fill ko-progress-circle__bar"></div>
                          </div>
                      </div>
                      <div className="ko-progress-circle__overlay"></div>
                  </div>

                  <span>
                    <img src={playerPause} alt=""/>
                  </span>
                </div>
                }

                {this.imageHasDownloads(this.state.data) && (
                <div className="module_audio__download">
                  <button onClick={() => { this.showModal(this.state.data) }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><defs><path id="wee3a" d="M1468 2620v4a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2v-4"/><path id="wee3b" d="M1454 2615l5 5 5-5"/><path id="wee3c" d="M1459 2620v-12"/></defs><g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3a"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3b"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3c"/></g></g></svg>
                  </button>
                </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Audio;

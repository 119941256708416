import React, { Component } from 'react';

import Audio      from './audio/Audio';
import Button     from './button/Button';
import Cards      from './cards/Cards';
import Color      from './color/Color';
import Embed      from './embed/Embed';
import Image      from './image/Image';
import ImageText  from './imagetext/ImageText';
import Snippet    from './snippet/Snippet';
import Text       from './text/Text';
import Video      from './video/Video';
import VideoText  from './videotext/VideoText';

class Loader extends Component {

  constructor() {
    super();
    this.state = {
      data: []
    }
  }

  componentDidMount() {
    this.setState({
      data: this.props.data
    });
  }

  render () {
    return (
        <div className="module_content_include">
          {this.state.data.module === 'audio' && (
            <Audio
              data={this.state.data}
              audio={this.props.audio}
              audioStatus={this.props.audioStatus}
              audioProgress={this.props.audioProgress}
              playAudio={this.props.playAudio}
              pauseAudio={this.props.pauseAudio}
              fullWidth={this.props.fullWidth}
              openModal={this.props.openModal}
            />
          )}

          {this.state.data.module === 'button' && (
          <Button brand={this.props.brand} data={this.state.data} fullWidth={this.props.fullWidth} />
          )}

          {this.state.data.module === 'cards' && (
          <Cards brand={this.props.brand} data={this.state.data} fullWidth={this.props.fullWidth} />
          )}

          {this.state.data.module === 'colors' && (
          <Color brand={this.props.brand} data={this.state.data} fullWidth={this.props.fullWidth} />
          )}

          {this.state.data.module === 'embed' && (
          <Embed data={this.state.data} fullWidth={this.props.fullWidth} />
          )}

          {this.state.data.module === 'image' && (
          <Image data={this.state.data} openModal={this.props.openModal} fullWidth={this.props.fullWidth} layout={this.props.layout} zoomImages={this.props.zoomImages} />
          )}

          {this.state.data.module === 'imagetext' && (
          <ImageText
            brand={this.props.brand}
            data={this.state.data}
            openModal={this.props.openModal}
            fullWidth={this.props.fullWidth}
            layout={this.props.layout}
            zoomImages={this.props.zoomImages}
            imageVideoTextPosition={this.props.imageVideoTextPosition} />
          )}

          {this.state.data.module === 'snippet' && (
          <Snippet data={this.state.data} />
          )}

          {this.state.data.module === 'paragraph' && (
          <Text brand={this.props.brand} data={this.state.data} fullWidth={this.props.fullWidth} isMasthead={this.props.isMasthead} />
          )}

          {this.state.data.module === 'video' && (
          <Video data={this.state.data} openModal={this.props.openModal} fullWidth={this.props.fullWidth} />
          )}

          {this.state.data.module === 'videotext' && (
          <VideoText
            brand={this.props.brand}
            data={this.state.data}
            openModal={this.props.openModal}
            fullWidth={this.props.fullWidth}
            imageVideoTextPosition={this.props.imageVideoTextPosition} />
          )}
        </div>
    );
  }
}

export default Loader;

import React, { Component } from 'react';
import './Modal.scss';

class Modal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false
    }
  }

  componentDidMount() {
    let self = this;

    window.addEventListener('contextmenu', function(ev) {
      ev.preventDefault();
      self.setState({
        visible: true,
      });
      return false;
    }, false);

    document.addEventListener("mouseup", function(event) {
      var text = window.getSelection().toString();
      if(text) {
        if (window.getSelection) {
          if (window.getSelection().empty) {  // Chrome
            window.getSelection().empty();
          } else if (window.getSelection().removeAllRanges) {  // Firefox
            window.getSelection().removeAllRanges();
          }
        } else if (document.selection) {  // IE?
          document.selection.empty();
        }

        self.setState({
          visible: true,
        });
      }
    }, false);
  }

  hideImageModal = () => {
    this.setState({
      visible: false,
    });
  }

  render () {
    return (
      <div>
        <div className={'locked-overlay' + (this.state.visible ? ' visible' : '')} onClick={() => { this.hideImageModal() }}></div>
        <div className={'locked-content' + (this.state.visible ? ' visible' : '')}>
          <div>
            <h4>Demo Showcase is for your inspiration only</h4>
            <p>This is a privacy-conscious brand book showcase. These are live duplicates from actual guidelines with only non-sensitive content and a locked option to edit, download, and copy.</p>
            <button onClick={() => { this.hideImageModal() }}>Got it</button>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;

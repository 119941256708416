import React, { Component } from 'react';

import './Embed.scss';

class Embed extends Component {

  constructor() {
    super();
    this.state = {
      data: [],
      full_width: false
    }
  }

  componentDidMount() {
    this.setState({
      data: this.props.data,
      full_width: this.props.fullWidth
    });

    setTimeout(function(){
      if (window.instgrm) {
        window.instgrm.Embeds.process();
      }
      //window.twttr.widgets.load();
      //window.FB.XFBML.parse();
    },2000);
  }

  render () {
    return (
      <div>
        <style type="text/css">{`
          .core_embed_`+this.state.data.id+` iframe {
            border-radius: `+this.state.data.border_radius+`px;
            border: `+this.state.data.border_width+`px solid `+this.state.data.border_color+`;
          }
        `}</style>
        <div className="module_embed">
          <div className="dynamic-content" style={{'width':(this.state.full_width ? '100%' : this.state.data.width+'px'), 'left':(this.state.full_width ? 0 : this.state.data.left)+'px', 'marginTop':(this.state.full_width ? 0 : this.state.data.top)+'px'}}>
                <div className={'module_embed--'+this.state.data.source+' core_embed_'+this.state.data.id} dangerouslySetInnerHTML={{__html: this.state.data.embed}}></div>
            </div>
        </div>
      </div>
    );
  }
}

export default Embed;

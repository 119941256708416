import React, { Component } from 'react';
import './Header.scss';

class Header extends Component {

	constructor() {
    super();
    this.state = {
      brand: false,
      token: ''
		}
	}

	UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.brand) {
      this.setState({
        brand: newProps.brand,
        token: localStorage.getItem('authToken') ? '?token=' + localStorage.getItem('authToken') : ''
      });
			document.body.classList.add('with--static-header');
    }
  }

  render () {
    return (
      <div>
				{this.state.brand &&
        <div className="static-header">
					<div className="static-header__logo">
						<a href={this.state.brand.organization.main.public + this.state.token}>
						<img src={'https://assets.'+window.MAIN_DOMAIN+'/editor/images/HelloFresh_Group_Home_Icon_solid.svg'} alt=""/>
						</a>
					</div>

        	<div className="static-header__brands">
            <div className="trigger-dropdown">
              <svg width="24" height="24" viewBox="0 0 24 24"><path d="M17.53 10.07l-5 5a.75.75 0 0 1-1.06 0l-5-5A.75.75 0 1 1 7.53 9L12 13.48 16.47 9a.75.75 0 1 1 1.06 1.06z"></path></svg>
              Menu

              <div className="dropdown-brands">
                <ul>
									{this.state.brand.organization.brands.map(item =>
                  <li key={item.id}>
                    <a href={item.public + this.state.token} title={item.title} className={item.id === this.state.brand.link ? 'active' : ''} dangerouslySetInnerHTML={{__html: item.title}}></a>
                  </li>
									)}
                </ul>
              </div>
            </div>
        	</div>
        </div>
				}
      </div>
    );
  }
}

export default Header;

import React, { Component } from 'react';
import axios from "axios";
import './Login.scss';

import ElonEduLogo from 'assets/img/01-elonSig-primary-reverse-rgb.png';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email      : '',
      password   : '',
      emailValid : false,
      studio      : '',
      page       : '',
      showError  : false,
      errorText  : 'Enter valid email and password!',
      token      : false,
      validToken : false,
      screen     : 'email',
      isSending  : false,
      dataRoom   : false,
      revertStyle : false,
      extra: false,
    }

    this.clickRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleForm   = this.toggleForm.bind(this);
  }

  componentDidMount() {
    this.setState({
      brand: this.props.brand,
      studio: this.props.studio,
      page: this.props.page,
      menu: this.props.menu,
      token: localStorage.getItem('authToken'),
      validToken: this.props.brand.valid_token,
      dataRoom: this.props.brand.id === 9778,
      revertStyle: false,
      extra: this.props.extra,
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.brand) {
      this.setState({
        brand: newProps.brand,
        studio: newProps.studio,
        page: newProps.page,
        menu: newProps.menu,
        token: localStorage.getItem('authToken'),
        validToken: newProps.brand.valid_token,
        dataRoom: newProps.brand.id === 9778,
        revertStyle: false,
        extra: newProps.extra,
  	  });
    }
  }

  toggleForm = () => {
		this.setState({
			screen: this.state.screen === 'email' ? 'password' : 'email',
		});
	};

  handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		});

		this.validateField(name, event.target.value);
	};

  validateField(fieldName, value) {
		let emailValid = this.state.emailValid;
		let passwordValid = this.state.passwordValid;

		switch (fieldName) {
			case 'email':
				emailValid = value.match(/^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? true : false;
				break;
			case 'password':
				passwordValid = (value.length >= 2) ? true : false;
				break;
			default:
				break;
		}

		this.setState({
			emailValid: emailValid,
			passwordValid: passwordValid
		});
	}

  handleSubmit = evt => {
		evt.preventDefault();

    if(this.state.isSending) {
      return false;
    }

    this.setState({
      showError: false,
      errorText: '',
      isSending: true,
      extra: false
    });

		let self = this;

    if(!this.state.emailValid) {
      this.setState({
        showError: true,
        errorText: 'Enter valid email!',
        isSending: false
      });

      return false;
    }

		axios.post(window.API + 'authorize/email', {
			email: this.state.email,
      brand: this.state.brand.link,
      studio: this.state.studio,
      page: this.state.page.page_id,
      redirect: window.location.href.split('?')[0]
		},{
      headers: {
        Pragma: 'no-cache'
      }
    }).then(function (response) {

      // Status OK, save token in localstorage, reload page or redirect to SSO
      if(response.data.status) {
        if (response.data.url) {
          window.location.href = response.data.url;
        } else {
          self.setState({
            screen: 'password',
            isSending: false
          });
        }
      } else {
        self.setState({
          showError: true,
          errorText: response.data.user ? 'You don\'t have access to this brand' : 'Incorrect email!',
          isSending: false
        });
      }
		}).catch(error => {
      self.setState({
        showError: true,
        errorText: error,
        isSending: false
      });
		})
	};

  handleSubmitPassword = evt => {
		evt.preventDefault();

    if(this.state.isSending) {
      return false;
    }

    this.setState({
      showError: false,
      errorText: '',
      isSending: true
    });

		let self = this;

    if(!this.state.passwordValid) {
      this.setState({
        showError: true,
        errorText: 'Enter password!',
        isSending: false
      });

      return false;
    }

		axios.post(window.API + 'authorize/password', {
			email: this.state.email,
			password: this.state.password,
      brand: this.state.brand.link,
      studio: this.state.studio,
      page: this.state.page.page_id
		},{
      headers: {
        Pragma: 'no-cache',
      }
    }).then(function (response) {

      localStorage.setItem('restoreToken', response.data.token);

      // Status OK, save token in localstorage, reload page
      if (response.data.status) {
        localStorage.setItem('authToken', response.data.token);
        localStorage.setItem('authUser', JSON.stringify(response.data.user));
        window.location.reload();
      } else {

        // If should update password
        if (response.data.reason === 'update') {
          localStorage.setItem('restoreToken', response.data.token);
          self.clickRef.current.click();

        // Incorrect password
        } else {
          self.setState({
            showError: true,
            errorText: 'Incorrect password!',
            isSending: false
          });
        }
      }
		}).catch(error => {
      self.setState({
        showError: true,
        errorText: error,
        isSending: false
      });
		})
	};

  render () {
    return (
      <div>
        <div className="login login--elon">
          <div className="login__form">
            <img src={ElonEduLogo} alt="Elon University logo" />

            <h5>Elon University Brand Guidelines Login</h5>

            {this.state.brand && !this.state.validToken && this.state.screen === 'email' &&
            <div>
              <p>Please enter your elon.edu email address below to login.</p>

              <form onSubmit={this.handleSubmit} noValidate>
                <div className="form-error">
                  <div className={this.state.showError ? 'visible' : ''}>{this.state.errorText}</div>
                  <div className={this.state.extra ? 'visible visible--extra' : ''}>{this.state.extra}</div>
                </div>

                <div className="form-group">
                  <input
                    type="email"
                    value={this.state.email}
                    placeholder="Enter email"
                    onChange={this.handleChange('email')}
                    />
                </div>

                <div className="form-group">
                  <input type="submit" value={this.state.isSending ? 'Wait...' : 'Continue'} onClick={this.handleSubmit} />
                </div>
              </form>

              <p className="request">External partners and vendors may <span className="underline" onClick={() => this.props.switchScreen('request',this.state.brand,this.state.studio,this.state.page)}>request access</span> from the Office of University Communications</p>
            </div>
            }

            {!this.state.validToken && this.state.screen === 'password' &&
            <div>
              <p>Enter password for<br/>{this.state.email}</p>

              <form onSubmit={this.handleSubmitPassword} noValidate>
                <div className="form-error">
                  <div className={this.state.showError ? 'visible' : ''}>{this.state.errorText}</div>
                </div>

                <div className="form-group">
                  <input
                    type="password"
                    value={this.state.password}
                    placeholder="Enter password"
                    onChange={this.handleChange('password')}
                    autoComplete="new-password"
                    />
                </div>

                <div className="forgot-link">
                  <a href={window.EDITOR_URL + '/#!/forgot'}>Forgot password?</a>
                </div>

                <div className="form-group">
                  <input type="submit" value={this.state.isSending ? 'Wait...' : 'Login'} onClick={this.handleSubmitPassword} />
                </div>
              </form>

              <div className="sso-link" onClick={this.toggleForm}>Change user</div>
            </div>
            }
          </div>
        </div>

        <div ref={this.clickRef} onClick={() => this.props.switchScreen('update', this.state.brand, this.state.studio, this.state.page)}></div>
      </div>
    );
  }
}

export default Login;

import React, { Component } from 'react';

import './Heading.scss';
import ShareLocation from '../../partials/share/ShareLocation';
import VideoBackground from '../../partials/video/VideoBackground';

import { calculatePadding } from '../../../utils';

const windowWidth = window.innerWidth;

class Heading extends Component {

  constructor() {
    super();
    this.state = {
      data: []
    }
  }

  componentDidMount() {
    this.setState({
      data: this.props.data
    });
  }

  render () {
    return (
      <div
        className={'module module--heading'}
        style={{
          paddingTop: calculatePadding(windowWidth, this.state.data.row_padding_top)+'px',
          paddingBottom: calculatePadding(windowWidth, this.state.data.row_padding_bottom)+'px',
          backgroundColor:this.state.data.background,
          backgroundImage:this.state.data.bg_image ? 'url('+this.state.data.bg_image+')' : 'none',
          backgroundPosition:this.state.data.bg_image_x+'% '+this.state.data.bg_image_y+'%'
        }}
      >
        <VideoBackground src={this.state.data.bg_video} />
        {this.state.data.dim === 1 && (
          <div className="module-bg-dim"></div>
        )}
        <ShareLocation module={this.state.data.id} />
        <div className="module_heading row">
          <div className="col-12">
            <div className="dynamic-content" style={{'width':this.state.data.text_width+'px', 'left':this.state.data.text_left+'px', 'top':this.state.data.text_top+'px'}}>
              <div className="editor-content" dangerouslySetInnerHTML={{__html: this.state.data.text}}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Heading;

import React, { Component } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import './Typography.scss';

import copyIcon from '../../../images/modules/ico-copy-file.svg';

class Typography extends Component {

  constructor() {
    super();
    this.state = {
      data: [],
      tester: 'Type here...'
    }

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      data: this.props.data,
    });

    if(this.props.data.provider === 'google') {
      window.WebFont.load({
        google: {
          families: [this.props.data.font + this._makeGoogleVariations(this.props.data.items)]
        }
      });
    }

    if(this.props.data.provider === 'typekit') {
      window.WebFont.load({
        typekit: {
          id: this.props.data.typekit_kit_id
        }
      });
    }

    if(this.props.data.provider === 'custom') {
      let url = window.API.replace("/brand/", "/");
      window.WebFont.load({
        custom: {
          families: [this.props.data.font],
          urls: [url + this.props.data.stylesheet]
        }
      });
    }
  }

  handleChange(event) {
    this.setState({tester: event.target.value});
  }

  _makeGoogleVariations = (items) => {
    let variations = [], i = 0;
    for(i=0;i<items.length;i++){
      let weight = items[i].weight;
      let style  = items[i].style;

      if(weight !== '' && weight !== 'regular'){
        variations.push(weight + (style === 'italic' ? 'i' : ''));
      }
    }

    if(variations.length){
      return ':' + variations.join();
    }

    return '';
  }

  render () {

    return (
      <div>
        <div className={'module module--heading module--'+this.state.data.padding}>
          <div className="module-typography row">
            <div className="col-12">
              <div className="dynamic-content" style={{'width':this.state.data.text_width+'px', 'left':this.state.data.text_left+'px', 'top':this.state.data.text_top+'px'}}>
                <div className="editor-content" dangerouslySetInnerHTML={{__html: this.state.data.text}}></div>
              </div>
            </div>
          </div>
        </div>

        <div className="module module-typography--embed">
          {this.state.data.provider !== 'custom' &&
          <div className="row">
            <div className="col-12">
              <h1>HTML</h1>
              {this.state.data.provider !== 'typekit' &&
              <div className="module-typography--embed__embed">
                {this.state.data.embed}
                <CopyToClipboard text={this.state.data.embed}>
                  <img src={copyIcon} alt="Copy" />
                </CopyToClipboard>
              </div>
              }

              {this.state.data.provider === 'typekit' &&
              <div className="module-typography--embed__embed">
                <pre>{`
<script type="text/javascript">
  (function(d) {
    var config = {
      kitId: '`}{this.state.data.typekit_kit_id}{`',
      scriptTimeout: 3000,
      async: true
    },
    h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
  })(document);
</script>
                `}</pre>
                <CopyToClipboard text={`
<script type="text/javascript">
  (function(d) {
    var config = {
      kitId: '`+this.state.data.typekit_kit_id+`',
      scriptTimeout: 3000,
      async: true
    },
    h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
  })(document);
</script>
                `}>
                  <img src={copyIcon} alt="Copy" />
                </CopyToClipboard>
              </div>
              }
            </div>
          </div>
          }

          <div className="row">
            <div className="col-12">
              <h1 className="css--h1">CSS style</h1>
              <div className="module-typography--embed__embed">
                font-family:{this.state.data.style}
                <CopyToClipboard text={'font-family:'+this.state.data.style}>
                  <img src={copyIcon} alt="Copy" />
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>

        {this.state.data.letters && (
        <div className="module module--grey module-typography--tester">
          <div className="row">
            <div className="col-12">
              <h1>{this.state.data.name}</h1>

              <div className="tester-input">
                <label>Type tester</label>
                <input type="text" placeholder={this.state.tester} onChange={this.handleChange} />
              </div>

              {this.state.data.items.length === 0 &&
              <div className="tester-results">
                <div className="row">
                  <div className="col-lg-4">
                    <span className="letters" style={{fontFamily:this.state.data.name}}>{this.state.data.letters}</span>
                  </div>
                  <div className="col-lg-8">
                    <div className="tester-results__result">
                      <h2 style={{fontFamily:this.state.data.style}}>{this.state.data.name}</h2>
                      <div className="tester-results__result__preview">
                        <p style={{fontFamily:this.state.data.style}}>{this.state.tester}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              }

              {this.state.data.items.map(item =>
              <div key={item.id} className="tester-results">
                <div className="row">
                  <div className="col-lg-4">
                    <span className="letters" style={{fontFamily:this.state.data.style,fontWeight:item.weight,fontStyle:item.style}}>{this.state.data.letters}</span>
                  </div>
                  <div className="col-lg-8">
                    <div className="tester-results__result">
                      <h2 style={{fontFamily:this.state.data.style,fontWeight:item.weight,fontStyle:item.style}}>{this.state.data.name}</h2>
                      {item.weight && <div>Weight: {item.weight}</div>}
                      {item.style && <div>Style: {item.style}</div>}
                      <div className="tester-results__result__preview">
                        <p style={{fontFamily:this.state.data.style,fontWeight:item.weight,fontStyle:item.style}}>{this.state.tester}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              )}
            </div>
          </div>
        </div>
        )}
      </div>
    );
  }
}

export default Typography;

import React, { Component } from 'react';
import './Home.scss';

class Home extends Component {

  componentWillMount() {
    window.location = this.props.studio ? 'https://studio.'+window.MAIN_DOMAIN : 'https://content.'+window.MAIN_DOMAIN;
  }

  render () {
    return (
      <div>

      </div>
    );
  }
}

export default Home;
